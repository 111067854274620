var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title mb-4"},[_vm._v("Settings")]),_c('div',{staticClass:"form-check form-switch fs-5"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"maintenace"}},[_vm._v("Exchange Under Maintenance")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.switch1),expression:"switch1"}],staticClass:"form-check-input",attrs:{"type":"checkbox","role":"switch","id":"maintenance"},domProps:{"checked":Array.isArray(_vm.switch1)?_vm._i(_vm.switch1,null)>-1:(_vm.switch1)},on:{"change":[function($event){var $$a=_vm.switch1,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.switch1=$$a.concat([$$v]))}else{$$i>-1&&(_vm.switch1=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.switch1=$$c}},function($event){return _vm.showSwal(
                  'Are you sure you want to change Exchange Under-Maintenance Mode?',
                  'maintenance'
                )}]}})]),_c('div',{staticClass:"form-check form-switch fs-5"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"withdrawls"}},[_vm._v("All Withdrawls Allow")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.switch2),expression:"switch2"}],staticClass:"form-check-input",attrs:{"type":"checkbox","role":"switch","id":"withdrawls"},domProps:{"checked":Array.isArray(_vm.switch2)?_vm._i(_vm.switch2,null)>-1:(_vm.switch2)},on:{"change":[function($event){var $$a=_vm.switch2,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.switch2=$$a.concat([$$v]))}else{$$i>-1&&(_vm.switch2=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.switch2=$$c}},function($event){return _vm.showSwal(
                  'Are you sure you want to change all withdrawls setting?',
                  'withdraw'
                )}]}})]),_c('div',{staticClass:"form-check form-switch fs-5"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"withdrawls"}},[_vm._v("All Deposits Allow")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.switch3),expression:"switch3"}],staticClass:"form-check-input",attrs:{"type":"checkbox","role":"switch","id":"deposits"},domProps:{"checked":Array.isArray(_vm.switch3)?_vm._i(_vm.switch3,null)>-1:(_vm.switch3)},on:{"change":[function($event){var $$a=_vm.switch3,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.switch3=$$a.concat([$$v]))}else{$$i>-1&&(_vm.switch3=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.switch3=$$c}},function($event){return _vm.showSwal(
                  'Are you sure you want to change all deposits setting?',
                  'deposit'
                )}]}})])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }