<script>
// import Multiselect from "vue-multiselect";
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../api/api";
import Swal from "sweetalert2";

/**
 * Contacts-grid component
 */
export default {
  page: {
    title: "Settings",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      switch1: false,
      switch2: false,
      switch3: false,

      title: "Settings",
      items: [
        {
          text: "Settings",
          href: "/admin",
        },
      ],
    };
  },
  async mounted() {
    var data = await ApiClass.getNodeRequest("user/get/authority", true);
    // console.log(data);
    var arrayData = data.data.data;
    var switch1Val = arrayData?.find(
      (element) => element.type == "maintenance"
    );
    this.switch1 = switch1Val?.status == "on" ? true : false;
    var switch2Val = arrayData?.find((element) => element.type == "withdraw");
    this.switch2 = switch2Val?.status == "on" ? true : false;
    var switch3Val = arrayData?.find((element) => element.type == "deposit");
    this.switch3 = switch3Val?.status == "on" ? true : false;

    // status: "on";
    // type: "deposit";
  },
  methods: {
    showSwal(msg, elId) {
      this.$swal({
        title: "Please Confirm..",
        text: msg,
        icon: "warning",
        iconColor: "#ff0000",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#ff0000",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          if (elId == "maintenance") {
            this.nodeHit("maintenance", this.switch1);
          } else if (elId == "withdraw") {
            this.nodeHit("withdraw", this.switch2);
          } else {
            this.nodeHit("deposit", this.switch3);
          }
        } else {
          if (elId == "maintenance") {
            this.switch1 = !this.switch1;
          } else if (elId == "withdraw") {
            this.switch2 = !this.switch2;
          } else {
            this.switch3 = !this.switch3;
          }
        }
      });
    },

    async nodeHit(keyVal = null, switchVal = null) {
      var data = switchVal == true ? "on" : "off";
      var form = {
        type: keyVal,
        status: data,
      };

      var result = await ApiClass.postNodeRequest(
        "user/set/authority",
        true,
        form
      );

      // console.log(result);
      Swal.fire({
        position: "center",
        icon: "success",
        title: result.data.message,
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <filter section> -->

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Settings</h4>

            <div class="form-check form-switch fs-5">
              <label class="form-check-label" for="maintenace"
                >Exchange Under Maintenance</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="maintenance"
                v-model="switch1"
                @change="
                  showSwal(
                    'Are you sure you want to change Exchange Under-Maintenance Mode?',
                    'maintenance'
                  )
                "
              />
            </div>

            <div class="form-check form-switch fs-5">
              <label class="form-check-label" for="withdrawls"
                >All Withdrawls Allow</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="withdrawls"
                v-model="switch2"
                @change="
                  showSwal(
                    'Are you sure you want to change all withdrawls setting?',
                    'withdraw'
                  )
                "
              />
            </div>
            <div class="form-check form-switch fs-5">
              <label class="form-check-label" for="withdrawls"
                >All Deposits Allow</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="deposits"
                v-model="switch3"
                @change="
                  showSwal(
                    'Are you sure you want to change all deposits setting?',
                    'deposit'
                  )
                "
              />
            </div>

            <!-- No Result Found -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
